<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Update Transaction
        <div class="card-header-actions">
          <a
            class="card-header-action"
            href="buy-voucher"
            rel="noreferrer noopener"
          >
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row>
          <b-col sm="12">
            <b-form v-on:submit.prevent="onSubmit" novalidate>
              <b-form-group
                id="tracking_no"
                label="Nomo Resi"
                label-for="tracking_no"
              >
                <b-form-input
                  id="tracking_no"
                  type="text"
                  v-model.lazy.trim="$v.form.tracking_no.$model"
                  :state="chkState('tracking_no')"
                  aria-describedby="trackingFeedBack"
                  autofocus
                  autocomplete
                />
                <b-form-invalid-feedback id="trackingFeedback">
                  <span v-if="!$v.form.tracking_no.required">
                    Nomor Resi tidak boleh.
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="status" label="Status" label-for="status">
                <b-form-select
                  id="status"
                  v-model="form.status"
                  aria-describedby="statusFeedback"
                  :plain="true"
                  :options="[
                    'Menunggu Pembayaran',
                    'Sedang Diproses',
                    'Dikirim',
                    'Dibatalkan',
                    'Expired',
                    'Berhasil',
                  ]"
                >
                </b-form-select>
              </b-form-group>
              <b-button type="submit" variant="primary"> Submit </b-button>
            </b-form>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      form: {
        tracking_no: "",
        status: "",
      },
      isLoading: false,
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      tracking_no: {
        required,
      },
    },
  },
  created() {
    this.$http.get(`reseller/` + this.$route.params.id).then((res) => {
      let result = res.data.data;
      this.form.tracking_no =
        result.destination.tracking_no === "-"
          ? ""
          : result.destination.tracking_no;
      this.form.status = result.destination.status;
    });
  },
  methods: {
    onFileSelected(event) {
      this.form.selectedImage = event.target.files[0];
    },
    changePicker(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;

        const payload = {
          _method: "put",
          tracking_no: this.form.tracking_no,
          status: this.form.status,
        };
        this.$http
          .post(`reseller/${this.$route.params.id}`, payload)
          .then(() => {
            this.isLoading = false;
            this.$router.push("/buy-voucher");
            this.$toasted.success("Voucher Transaction Successfully updated.");
          })
          .catch((error) => {
            if (error.response) {
              this.isLoading = false;
              if (error.response.data.meta.code == 422) {
                this.$toasted.error(
                  "Please fill out the form that must be required"
                );
              } else if (error.response.data.meta.code == 400) {
                this.$swal.fire(
                  "Failed!",
                  error.response.data.meta.message,
                  "error"
                );
              }
            }
          });
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
  },
};
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}

#dateFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />

